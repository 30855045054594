import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import NotFoundIll from "./assets/illustrations/not-found.svg";

!(process.env.NODE_ENV === "development") &&
  Sentry.init({
    dsn: "https://0df6bb2fe5e045689f141ede2015191e@o4503904185024512.ingest.sentry.io/4504346411139072",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      {/* <App /> */}
      <h1 className="text-2xl bg-primary-dark rounded-md text-center mx-auto">We are coming back soon</h1>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
