import CryptoJS from "crypto-js";
const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_IV);

export function formatPublishedData(data) {
  let statusObj = {};
  data.forEach((item) => {
    let key = Object.keys(item)[0];
    if (statusObj[key]) {
      statusObj[key] = item[key].published
        ? {
            published: statusObj[key].published + 1,
            unpublished: statusObj[key].unpublished,
          }
        : {
            unpublished: statusObj[key].unpublished + 1,
            published: statusObj[key].published,
          };
    } else {
      statusObj[key] = item[key].published
        ? {
            published: 1,
            unpublished: 0,
          }
        : { published: 0, unpublished: 1 };
    }
  });
  return statusObj;
}

export function encryptData(rawData) {
  let encryptedData = CryptoJS.AES.encrypt(rawData, key, { iv: iv });
  encryptedData = encryptedData.toString();
  return encryptedData;
}

export function decryptData(rawData) {
  let decryptedData = CryptoJS.AES.decrypt(rawData, key, { iv: iv });
  decryptedData = decryptedData.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

// decryptData("uJQ8IPEd4rwg6Fhr59Efng==");
