import React from "react";
import Signin from "./components/auth/Signin";
import Signup from "./components/auth/Signup";
import Dashboard from "./views/Home/Dashboard";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { UserDataContextProvider } from "./context/UserContext";
import { ToastContextProvider } from "./context/ToastContext";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import BaseLayout from "./components/BaseLayout";
import Integrations from "./views/Integrations/Integrations";
import ManageArticles from "./views/Articles/ManageArticles";
import Analytics from "./views/Analytics/Analytics";
import Settings from "./views/Settings/Settings";
import Landing from "./components/Landing";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Forgotpass from "./components/auth/Forgotpass";

function App() {
  return (
    <>
      <ToastContextProvider>
        <AuthContextProvider>
          <UserDataContextProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <PublicRoute>
                    <Landing />
                  </PublicRoute>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <PublicRoute>
                    <Forgotpass />
                  </PublicRoute>
                }
              />
              <Route
                path="signin"
                element={
                  <PublicRoute>
                    <Signin />
                  </PublicRoute>
                }
              />
              <Route
                path="signup"
                element={
                  <PublicRoute>
                    <Signup />
                  </PublicRoute>
                }
              />
              {/* private routes */}
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <BaseLayout />
                  </PrivateRoute>
                }
              >
                {/* Private pages starts  */}
                <Route
                  path="dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="integrations"
                  element={
                    <PrivateRoute>
                      <Integrations />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="manage-articles"
                  element={
                    <PrivateRoute>
                      <ManageArticles />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="analytics"
                  element={
                    <PrivateRoute>
                      <Analytics />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <Navigate to="/dashboard" />
                    </PrivateRoute>
                  }
                />
                {/* Private Pages Ends  */}
              </Route>
            </Routes>
            <div>
              <ToastContainer />
            </div>
          </UserDataContextProvider>
        </AuthContextProvider>
      </ToastContextProvider>
    </>
  );
}

export default App;
